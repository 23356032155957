<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.63574 3.27454L9.63574 3.6277C9.62795 3.65627 9.61497 3.68224 9.60978 3.71081C9.49551 4.45353 8.87744 5.04044 8.1529 5.08979C7.34265 5.14693 6.65706 4.68467 6.43372 3.93156C6.40516 3.83288 6.38438 3.729 6.35841 3.62773L6.35841 3.27454C6.3662 3.25117 6.37919 3.2304 6.38178 3.20443C6.49345 2.51883 6.98687 1.99685 7.66208 1.84882C7.71402 1.83843 7.76596 1.82285 7.81789 1.80987L8.17108 1.80987C8.19445 1.81766 8.21782 1.83064 8.2412 1.83324C8.9164 1.94231 9.43059 2.42275 9.58642 3.09017C9.602 3.1551 9.62018 3.21483 9.63576 3.27456L9.63574 3.27454ZM6.3584 13.2572L6.35841 12.904C6.3662 12.8754 6.37919 12.8494 6.38178 12.8209C6.49865 12.0729 7.11932 11.4886 7.85166 11.4419C8.65931 11.3899 9.3423 11.8574 9.56304 12.6131C9.59161 12.7092 9.61239 12.8079 9.63576 12.904L9.63576 13.2572C9.62797 13.2805 9.61498 13.3013 9.60979 13.3247C9.48513 13.9999 9.093 14.4414 8.43857 14.6517C8.35287 14.6803 8.26197 14.6959 8.17368 14.7192L7.82049 14.7192C7.79712 14.7114 7.77375 14.6985 7.75037 14.6959C7.07257 14.5842 6.56097 14.1064 6.40516 13.4389C6.38956 13.3766 6.37398 13.3169 6.3584 13.2572ZM6.35841 8.44242L6.35841 8.08923C6.3662 8.06067 6.37919 8.0347 6.38438 8.00613C6.50124 7.26081 7.14529 6.66091 7.87243 6.62975C8.69047 6.59339 9.38385 7.08681 9.57863 7.84772C9.5994 7.92822 9.61758 8.00873 9.63576 8.08923L9.63576 8.44242C9.62797 8.47098 9.61498 8.49695 9.60979 8.52552C9.49552 9.27084 8.84888 9.87075 8.12174 9.9045C7.3037 9.94345 6.61032 9.44744 6.41554 8.68653C6.39477 8.60343 6.37659 8.52292 6.35841 8.44242Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDotsV'
}
</script>
